@import './styles/mixins.scss';
.container {
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    color: white;

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        height: 80px;
        width: 80%;
        line-height: 60px;
        font-weight: bold;
        background: transparent;
        // backdrop-filter: blur(2px);
        z-index: 10;
        margin: 0 auto;

        &-title {
            margin-left: 10%;
        }

        .logo-holder {
            display: flex;

            img {
                max-width: 100%;
                height: 100%;
            }
        }

        .header-divider {
            position: relative;
            height: 100%;
            width: 1px;
            background: #fff;
            margin-left: 38px;
            transform: translateY(-200px);
            animation: slideIn .6s ease forwards;
            // animation-delay: .2s;
            &:after{
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                background: #fff;
                bottom: 0;
                left: -2px;
                opacity: 0;
                animation: fadeIn .2s ease forwards;
                animation-delay: .6s;
            }
        }

        nav {
            margin-left: 40px;
            align-items: center;
            display: flex;

            a {
                font-size: 18px;
                font-weight: 500;
                margin-right: 20px;
                color: #fff !important;
                text-decoration: none !important;
                position: relative;

                // background:
                //     linear-gradient(-45deg, #000  40%, #fff 0 60%, #000  0) right/300% 100% no-repeat,
                //     linear-gradient(-45deg, #0000 40%, #000 0 60%, #0000 0) right/300% 100% no-repeat;
                // -webkit-background-clip: text, padding-box;
                // background-clip: text, padding-box;
                &::after{
                    content: '';
                    height: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
                &:hover{
                    // background-position: left;
                    // color: black !important;
                    &::after{
                        height: 2px;
                    }
                    border-bottom: 2px solid #fff;
                }
            }

            @include phone{
                display: none;
            }

            @include iPad() {
                font-size: 12px;
                margin-left: 10px;
            }
        }

        &-connectWalletButton {
            margin-left: auto;
            @include phone{
                transform: scale(.8);
                justify-self: flex-end;
            }
            @include iPad() {
                transform: scale(.8);
                justify-self: flex-end;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: row;
        height: 80%;
        max-width: 100%;
        text-align: center;
    }

    &__footer {
        height: 10%;
        width: 100%;
        border-top: 0.5px solid rgb(129, 110, 110);
        text-align: center;
    }
}

@keyframes slideIn{
    to{
        transform: translateY(0);
    }
}

@keyframes fadeIn{
    to{
        opacity: 1;
    }
}