
@import '../../styles/mixins.scss';

.sideNav-container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255,255,255,.3);
    border-radius: 12px;
    width: 20%;
    min-height: 30em;
    margin-right: 20px;

    &__daoInfo {
        padding: 12px 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .avatar{
            height: 60px;
            width: 60px;
            margin-bottom: 10px;
            // border: 1px solid red;
            border-radius: 100%;
            overflow: hidden;

            img{
                max-width: 100%;
            }
        }
        .rid{
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: 1px solid rgba(255,255,255,.3);
            font-size: 14px;
            padding: 2px 6px;
            border-radius: 20px;
            margin: 10px 0;
        }
        .dao-meta{
            display: flex;
            justify-content: space-between;
            width: 70%;
            margin: 15px auto;
            &.text{
                text-align: left;
                width: 100%;
                font-size: 12px;
            }
            .link{
                height: 18px;
                font-size: 8px;
                font-weight: 300;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                align-content: center;
                justify-items: center;
                img{
                    height: 100%;
                    margin-bottom: 2px;
                }
            }
        }
    }

    &__nav {
        margin-top: 20%;
        nav{
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: flex-start;

            a.link{
                width: 100%;
                padding: 10px 0;
                position: relative;
                padding-left: 12px;
                transition: all .32s ease;
                text-align: left;
                &::before{
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 0;
                    transition: all .32s ease;
                    background: #fff;
                }
                &:hover{
                    &::before{
                        width: 4px;
                    }
                }
            }
        }

        @include desktop-l() {
            display: block;
        }
        @include phone() {
            display: none;
        }
        @include iPad{
            display: none;
        }
        // @include iPadPortrait() {
        //     display: none;
        // }
        // @include iPadAir() {
        //     display: none;
        // }
        // @include laptop() {
        //     display: block;
        // }
        // @include laptop-s() {
        //     display: block;
        // }
        // @include desktop-m() {
        //     display: block;
        // }
    }

    @include desktop-l() {
        width: 20%;
        min-height: 30em;
        margin-right: 20px;
    }

    @include phone() {
        width: 100%;
        margin: 0 0 10px 0;
        min-height: unset;
    }

    @include iPad{
        width: 100%;
        margin: 0 0 20px 0;
        min-height: unset;
    }

    // @include iPadPortrait() {
    //     width: 100%;
    //     margin: 0 0 20px 0;
    //     min-height: unset;
    // }

}