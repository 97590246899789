@import '../../styles//mixins.scss';

.canvasHolder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
}

.btn {
  width: 120px;
  height: 40px;
  line-height: 40px;
  color: #fff;
}

.uiHolder {
  box-sizing: border-box;
  padding: 0 10%;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  right: 0;
  top: 80px;
  // overflow: hidden;

  .textSection {
    margin-top: 20%;
    max-width: 35%;
    text-align: left;

    h1 {
      // font-weight: 500;
      font-size: 2.65em;
      margin-bottom: 30px;
    }

    p {
      font-size: 18px;
      animation-delay: .2s !important;
      // line-height: 24px;
      margin-bottom: 30px;
    }

    @include laptop-s(){
      margin-top: 12%;
      max-width: 45%;
    }

    @include iPadAir(){
      max-width: 75%;
    }

    @include phone{
      margin-top: 45%;
      max-width: 80%;
    }
  }

}