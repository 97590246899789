@import '../../mixins.scss';

.container {
  display: flex;
  width: calc(80%);
  margin: auto;
  padding-top: 4%;

  @include desktop-l() {
    flex-flow: row;
  }

  @include iPadAir() {
    width: 96%;
  }
  
  @include phone() {
    flex-flow: column;
  }

  @include iPad() {
    flex-flow: column;
    min-height: auto;
  }

  @include iPadPortrait() {
    flex-flow: column;
  }

}