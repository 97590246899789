@import '../../styles/mixins.scss';

@mixin blockBase {
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, .3);
    padding: 10px;

    h4 {
        text-align: left;
    }
}

.swiper-slide {
    text-align: center;

    img {
        max-width: 100%;
    }
}

.swiper-button-prev,
.swiper-button-next {
    color: #fff;
    transform: scale(.4);
}

.daoHome-container {
    display: flex;
    flex-flow: column;
    width: calc(80% - 20px);
    margin-bottom: 40px;

    .actions {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }

    &__top {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        @include iPad{
            flex-flow: column nowrap;
        }


        &-bankAccounts {
            @include blockBase();
            width: 50%;
            margin-right: 20px;
            position: relative;
            @include phone() {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;
            }
            @include iPad{
                width: 100%;
                margin-bottom: 20px;
            }
        }

        &-cards {
            @include blockBase();
            width: 50%;
            position: relative;
            animation-delay: .2s;
            @include phone() {
                width: 100%;
            }
            @include iPad{
                width: 100%;
            }
        }

        @include phone() {
            flex-flow: column nowrap;
        }
    }

    &__bottom {


        &-transactions {
            @include blockBase();

        }
    }

    @include phone{
        width: 100%;
    }

    @include iPad {
        width: 100%;       
    }
}

.modal-backdrop {
    position: fixed;
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin: auto;
    transition: all .6s ease-in;
    visibility: hidden;

    &.animated {
        visibility: visible;
        backdrop-filter: blur(3px);
        background: rgba(0, 0, 0, .3);
    }

    .modal-holder {
        min-width: 440px;
        background: #fff;
        padding: 22px 20px;
        color: #000;
        border-radius: 12px;
        box-shadow: 0 2px 14px 6px rgba(0, 0, 0, .8);
        position: relative;
        z-index: 21;

        h4 {
            margin-bottom: 12px;
        }
        @include phone{
            min-width: unset;
            width: 90%;
        }
    }

    .form-holder {
        .form-field {
            margin-bottom: 8px;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            align-content: center;
            justify-content: space-between;
            width: 100%;

            .label {
                font-size: 14px;
                margin-bottom: 4px;
                font-style: italic;
                opacity: .7;
            }

            input, textarea {
                font-size: 16px;
                padding: 4px 8px;
                border-radius: 12px;
                border: 1px solid rgba(0, 0, 0, .3);

                &[type='text'] {
                    width: 100%;
                }
            }
            textarea{
                width: 100%;
            }

            .label-span {
                font-size: 10px;
                margin-bottom: 2px;
                font-style: italic;
                opacity: .6;
            }

            .signatures {
                display: flex;
                margin-bottom: 6px;

                span {
                    font-size: 12px;
                    border: 1px solid red;
                    display: flex;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 80px;
                    padding: 2px 4px;
                    margin-right: 4px;
                    border-radius: 6px;
                }
            }

            &.row {
                flex-flow: row nowrap;

                .label {
                    cursor: pointer;
                    margin-right: 6px;
                    margin-bottom: 0;
                    width: 100%;
                }

                .field-btn {
                    font-size: 12px;
                    border: 1px solid rgb(0 10 255 / 30%);
                    padding: 6px 10px;
                    border-radius: 12px;
                    line-height: 18px;
                    margin-left: auto;
                    justify-self: flex-end;
                }

                input[type="text"] {
                    width: 74%;
                }
            }
        }

        text-align: left;
    }
}

.centered-btn {
    align-self: center;
}

@keyframes fadeIn {
    to {
        opacity: 1
    }
}


.list-holder {
    height: 100%;
    position: relative;
    text-align: left;
    padding-bottom: 60px;

    opacity: 0;
    animation: fadeIn .8s ease forwards;
    animation-delay: .8s !important;


    .list-item {
        margin-bottom: 4px;

        .part {
            font-size: 16px;
            // min-width: 16%;

            &.fullPart {
                width: 100%;
                margin-right: 0;
                border-bottom: 1px solid rgba(255, 255, 255, .3);
                padding-bottom: 4px;
            }
        }

        .list-label {
            opacity: .8;
            font-size: 10px;
            font-weight: 300;
            margin: 6px 0;
            width: 100%;
            display: flex;
            justify-content: space-between;

            &.ac-label {
                span {
                    &.title {
                        width: 25%;
                    }

                    &.num {
                        width: 45%;
                    }

                    &.bal {
                        width: 25%;
                    }
                    &.edit{
                        width: 5%;
                        text-align: right;
                    }
                }
            }

            &.tr-label {
                span {
                    &.date {
                        width: 20%;
                    }

                    &.hash-val {
                        width: 50%;
                    }

                    &.type {
                        width: 12%;
                        @include phone{
                            width: 18%;
                        }
                    }

                    &.sum {
                        width: 16%;
                        text-align: right;
                        padding-right: 10px;
                    }
                }
            }
        }

        .list-value {
            padding: 10px 0;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.ac-val {
                span {
                    display: flex;
                    &.title {
                        width: 25%;
                    }

                    &.num {
                        width: 45%;
                    }

                    &.bal {
                        width: 25%;
                    }
                    &.edit{
                        width: 5%;
                        text-align: right;
                        justify-content: flex-end;
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }

            &.tr-val {
                
                span {
                    &.date {
                        width: 20%;
                    }

                    &.hash-val {
                        width: 50%;
                        .hash{
                            display: inline-block;
                            max-width: 360px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            text-align: left;
                        }
                        @include phone{
                            width: 54%;
                        }
                    }

                    &.type {
                        width: 12%;
                        padding: 2px 4px;
                        font-size: 12px;
                        width: auto;
                        align-self: flex-start;
                        justify-self: flex-start;
                        text-align: left;
                        border-radius: 4px;
                        text-align: center;
                        background: rgb(12, 86, 235);
                        &.chain{
                            background: rgb(125, 208, 16);
                        }
                        &.card{
                            background: rgb(123, 22, 255);
                        }
                        @include phone{
                            font-size: 10px;
                            width: 24%;
                        }
                    }

                    &.sum {
                        width: 16%;
                        justify-self: flex-end;
                        margin-left: auto;
                        text-align: right;
                        padding-right: 10px;
                        @include phone{
                            width: 28%;
                            padding-right: 0;
                        }
                    }
                }
            }

            @include phone{
                font-size: 10px;
            }
        }
    }
}

.solIcon{
    width: 12px;
    height: 12px;
    color: #fff;
    @include phone {
        margin-bottom: -2px;
    }
}