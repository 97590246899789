.btnHolder {
  padding: 10px 30px;
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 50px;
  width: 100%;
  max-width: 220px;
  line-height: 28px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  transition: all .2s ease;
  text-transform: uppercase;
  animation-delay: .8s;
  user-select: none;
  font-weight: normal;
  &.smallBtn{
    padding: 6px 12px;
    line-height: 14px;
    font-size: 14px;
    width: auto;
    text-transform: capitalize;
    margin: 10px;
  }
  &.inverseColors{
    border: 1px solid rgba(0, 0, 0, .3);
    color: #000;
    &:hover{
      color: #fff;
      background: #000;
    }
  }

  &:hover {
    background-position: left;
    color: #000;
    background: #fff;
  }
}