@import '../../styles/mixins.scss';

$border: 1px solid rgba(255,255,255,.3);
$borderRadius: 20px;
.daos-holder{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 4%;
    margin:  0 auto;
    width: calc(80%);
    margin: auto;

    .search-holder{
        display: flex;
        .search-container{
            border: $border;
            border-radius: $borderRadius;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            overflow: hidden;
            position: relative;
            &::before{
                content: '';
                background: url('../../assets/search.png');
                background-repeat: no-repeat;
                background-size: 16px 16px;
                position: absolute;
                top: calc(50% - 8px);
                left: 12px;
                height: 16px;
                width: 16px;
            }
    
            input{
                padding: 10px 22px;
                padding-left: 34px;
                appearance: none;
                -webkit-appearance: none;
                border: 0;
                background: rgba(255,255,255,0);
                outline: none;
                color: #fff;
    
            }
        }
        .search-btn{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
            border: $border;
            align-self: center;
            justify-content: center;
            height: 100%;
            padding: 10px 22px;
            border-left: 0;
            font-size: 14px;
            font-weight: normal;
            cursor: pointer;
        }
    }
}
.daos-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 4%;

    &__dao {
        width: 180px;
        min-height: 150px;
        margin: 10px;
        border-radius: 10px;
        border: 1px solid rgb(129, 110, 110);
        text-decoration: none;
        text-decoration: none;
        color: white;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-items: flex-start;;
        padding: 10px 16px;

        .avatar-holder{
            width: 100%;
            height: 80px;
            width: 80px;
            margin-bottom: 10px;
            // border: 1px solid red;
            border-radius: 100%;
            overflow: hidden;

            img{
                max-width: 100%;
            }
        }

        p{
            width: 160px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        @include phone{
            margin: 0 28px 10px 0;
            width: 150px;
            &:nth-child(2n + 2) {
                margin-right: 0;
            }
        }
        @include phone-s {
            width: 140px;
            height: 150px;
            margin: 0 10px 10px 0;
        }
    }
}


.link {
    text-decoration: none;
    color: white;
}