
.connectWallet {
    border: 1px solid rgb(129, 110, 110);
    border-radius: 50px;
    margin: 1em;
    height: 50px;
    width: 10em;
    line-height: 50px;
    text-align: center;
    cursor: pointer
}